import React, { useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import Button from '@material-ui/core/Button';
import {changeFieldsWithPrefixAndCamelCase, getApiRoot, getLanguageValue, googleRecaptcha} from "../../common/functions";
import ModalComponent from "../Modal";
import CONSTANTS from '../../common/constants';
import lodashGet from 'lodash/get';
import GenericFieldComponent from "../GenericFieldComponent";
import Loader from "../Loader";
import {ACTIONS} from "../../redux/actions";
import {CSS_COLORS} from "../../common/cssColors";
import { reportCustomerEmailVerification } from "../../common/googleReporter";

function RegisterGroupStage1SubProcessComponent(props) {
    const {
        replacements,
        actions
    } = props;

    const [ isOpen, setIsOpen ] = useState(true);

    const [ errors, setErrors ] = useState({});
    const [ loaders, setLoaders ] = useState({ }); // generic loaders states (verifyCode, confirmCode, resendCode)
    const [ subProcessStage, setSubProcessStage ] = useState(1); // active stage
    const [ stage2Code, setStage2Code ] = useState('');
    const [ stage2CodeError, setStage2CodeError ] = useState(false);
    const [ stage2BottomError, setStage2BottomError ] = useState(false);
    const [ isSentCodeAgain, setIsSentCodeAgain ] = useState(false);

    const dispatch = useDispatch();

    // setup by stage
    let title = '';
    let content = '';

    switch (subProcessStage) {
        case 1:
            title = replaceValues(getLanguageValue('register-group.stage1-subprocess-verify-email'));
            content = <ModalContentStyle className={"modal-content-container"} >
                    <div className={"message-container"} dangerouslySetInnerHTML={{ __html: replaceValues(getLanguageValue('register-group.stage1-subprocess-verify-email-explanation')) }} />
                    <div className={"actions-container"}>
                        <Button disabled={loaders.verifyCode} className={"confirm-button common-style-primary-button"} onClick={stage1Continue}>{getLanguageValue('register-group.stage1-subprocess-confirm-button')} {loaders.verifyCode && <Loader className={"loader-component"} />}</Button>
                        <Button disabled={loaders.verifyCode} className={"change-email-button common-style-default-button"} onClick={stage1ChangeEmail}>{getLanguageValue('register-group.stage1-subprocess-change-email-button')}</Button>
                    </div>
                    <div className={"error-message-container field-warning-color"}>
                        {errors.verify ? <><br/>{getLanguageValue('register-network-error')}</> : ''}
                    </div>
                    <GlobalStyle />
                </ModalContentStyle>;
            break;
        case 2:
            title = getLanguageValue('register-group.stage1-subprocess-enter-code');
            content = <ModalContentStyle className={"modal-content-container"} >
                <div className={"message-container"} dangerouslySetInnerHTML={{ __html: replaceValues(isSentCodeAgain ? getLanguageValue('register-group.stage1-subprocess-enter-resend-code-explanation') : getLanguageValue('register-group.stage1-subprocess-enter-code-explanation')) }} />
                <div className={"actions-container"}>
                    <div className="code-input-container">
                        <GenericFieldComponent
                            fieldProps={{
                                autoFocus: true,
                                className: 'field-component',
                                disabled: isLoaderActive(),
                                error: stage2CodeError != false,
                                value: stage2Code,
                                inputProps: {
                                    maxLength: 4,
                                    name: 'stage2Code'
                                },
                                helperText: stage2CodeError || '',
                                onChange: (e) => { stage2Change4DigitCode(e); },
                                onKeyDown: (e) => { if (e.keyCode === 13) stage2Confirm(); },
                                label: getLanguageValue('register-group.stage1-subprocess-code-field-input')
                            }}
                        />
                    </div>

                    <Button disabled={isLoaderActive() || !stage2Code.length || stage2CodeError != false} className={"confirm-code-button common-style-primary-button"} onClick={stage2Confirm}>{getLanguageValue('register-group.stage1-subprocess-confirm-code-button')} {loaders.confirmCode && <Loader className={"loader-component"} />}</Button>
                    <Button disabled={isLoaderActive()} className={"resend-code-button common-style-default-button"} onClick={stage2ResendCode}>{getLanguageValue('register-group.stage1-subprocess-resend-code-button')} {loaders.resendCode && <Loader className={"loader-component"} />}</Button>
                    <Button disabled={isLoaderActive()} className={"cancel-button common-style-default-button"} onClick={stage2Cancel}>{getLanguageValue('register-group.stage1-subprocess-cancel-button')}</Button>
                </div>
                {stage2BottomError ? <div className={"error-container"}>{stage2BottomError}</div> : null}
                <GlobalStyle />
            </ModalContentStyle>;
    }

    return <ModalComponent
        isOpen={isOpen}
        modalClassName={'register-group-stage1-subprocess-modal'}
        disableBackdropClick={true}
        showTitleCloseButton={true}
        onClose={(src) => { if (isLoaderActive()) { return; } setIsOpen(false); actions.onClose('abort'); }}
        title={title}
        content={content}
    />;

    function replaceValues(originalValue) {
        if (replacements && originalValue) {
            for (let replacementItem of replacements) {
                originalValue = originalValue.replace(replacementItem.value, replacementItem.newValue);
            }
        }
        return originalValue;
    }

    // call the api and move to the next step
    async function stage1Continue() {
        let duplicateData = JSON.parse(JSON.stringify(props.stage1Data));
        delete duplicateData.emailConfirm;
        let isError = false;
        if (loaders.verifyCode) {
            return;
        }
        setErrors({ ...errors, verify: false });
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage1')
            })
        };
        try {
            setLoaders({ ...loaders, verifyCode: true });
            const url = getApiRoot() + 'init-process/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            if (!lodashGet(result, 'data.key')) {
                // error
                isError = true;
                setErrors({ ...errors, verify: true });
            }
            else {
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [
                        {
                            path: 'apiData.group.key',
                            value: lodashGet(result, 'data.key')
                        },
                        {
                            path: 'registerGroupStagesData',
                            value: { ...props.registerGroupStagesData, createDate: lodashGet(result, 'data.createDate') }
                        }
                    ]
                });
            }
        }
        catch (err) {
            // nothing to do
            setErrors({ ...errors, verify: true });
            isError = true;
        }
        setLoaders({ ...loaders, verifyCode: false });
        if (!isError) {
            setSubProcessStage(2);
        }
    }

    function stage1ChangeEmail() {
        actions.onClose('abort');
    }

    function stage2Change4DigitCode(e) {
        const value = e.target.value;
        setStage2Code(value);
        if (!value.length || /^\d{4}$/.test(value)) {
            setStage2CodeError(false);
        }
        else {
            setStage2CodeError(getLanguageValue('register-group.stage1-subprocess-code-field-error-message'));
        }
    }

    async function stage2Confirm() {
        setLoaders({ ...loaders, confirmCode: true });
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                key: props.apiGroupKey,
                code: stage2Code,
                recaptchaToken
            })
        };
        try {
            let url = getApiRoot() + 'confirm-code/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            if (!lodashGet(result, 'data.success')) {
                setStage2CodeError(getLanguageValue(result.err == 'code expired' ? 'register-group.stage1-subprocess-expired-code-error-message' : 'register-group.stage1-subprocess-invalid-code-error-message'));
            }
            else {
                reportCustomerEmailVerification();
                // code ok
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [
                        {
                            path: 'registerGroupStagesData.key',
                            value: props.apiGroupKey
                        }
                    ]
                });
                actions.onClose('success');
            }
        }
        catch (err) {
            // nothing to do
            setStage2CodeError(getLanguageValue('register-group.stage1-subprocess-invalid-code-error-message'));
        }
        setLoaders({ ...loaders, confirmCode: false });
    }

    async function stage2ResendCode() {
        setLoaders({ ...loaders, resendCode: true });
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: props.apiGroupKey
            })
        };
        try {
            const url = getApiRoot() + 'resend-email/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            setLoaders({ ...loaders, resendCode: false });
            if (!lodashGet(result, 'data.success')) {
                setStage2CodeError(getLanguageValue('register-group.stage1-subprocess-invalid-code-error-message'));
                return;
            }
            // code ok
            setIsSentCodeAgain(true);
        }
        catch (err) {
            // nothing to do
            setStage2CodeError(getLanguageValue('register-group.stage1-subprocess-invalid-code-error-message'));
            setLoaders({ ...loaders, resendCode: false });
            return;
        }
    }

    function stage2Cancel() {
        if (isLoaderActive()) {
            return;
        }
        actions.onClose('abort');
    }

    function isLoaderActive() {
        for (let loaderKey in loaders) {
            if (loaders[loaderKey]) {
                return true;
            }
        }
        return false;
    }
}

const GlobalStyle = createGlobalStyle`
  #modal-component .positioning-container {
    max-width: 600px;
    html.ltr & {
      max-width: 650px;
    }
  }
`;

const ModalContentStyle = styled.div`
  .actions-container {
    margin-top: 25px;
    text-align: center;
    > button {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 50px);
      margin-top: 10px;
    }
  }
  .field-component {
    margin-bottom: 20px;
  }
  .field-component input {
    text-align: center;
    font-size: 20px;
  }
  .loader-component {
    margin-right: 5px;
    margin-left: 5px;
  }
  .confirm-code-button, .resend-code-button, .cancel-button {
    white-space: nowrap;
    width: 140px;
  }
  .resend-code-button {
    width: calc(100% - 50px);
  }
  .error-container {
    margin-top: 10px;
    text-align: center;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterGroupStage1SubProcess = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        groupKey: lodashGet(state, 'registerGroupStagesData.key'),
        apiGroupKey: lodashGet(state, 'apiData.group.key'),
        stage1Data: lodashGet(state, 'registerGroupStagesData.stage1Data'),
        registerGroupStagesData: lodashGet(state, 'registerGroupStagesData'),
    }),
{})(RegisterGroupStage1SubProcessComponent);

export default RegisterGroupStage1SubProcess;
