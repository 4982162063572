import React, {useEffect} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {getContextRoot, getLanguageValue, navigateToPath} from "../../common/functions";
import lodashGet from 'lodash/get';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import { ACTIONS } from '../../redux/actions';

function RegisterIndividualSuccessComponent(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    useEffect(() => {
        actions.setActiveStage(2);
    }, []);

    return <RegisterIndividualSuccessStyle>
        <h1>{getLanguageValue('register-individual.stageSuccess-completed-successfully-title')}</h1>
        <div className={"content-container"}>
            <DoneIcon className={"icon-component"} /><span className={"completed-successfully-subtext"}>{getLanguageValue('register-group.stageSuccess-completed-successfully-subtext')}</span>
            <br/><br/>
            *<span>{getLanguageValue('register-group.stageSuccess-your-form-number')}{props.groupKey.toUpperCase()}</span>
            <br/><br/>
            <Button className="check-status-button" onClick={checkStatus}>{getLanguageValue('register-group.stageSuccess-check-status')}</Button>
        </div>
    </RegisterIndividualSuccessStyle>;

    function checkStatus() {
        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [{
                path: 'globalControls.refreshGroupData',
                value: true
            }]
        });
        navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/status');
    }
}

const RegisterIndividualSuccessStyle = styled.div`
  width: 80%;
  margin: 0 auto;
  .content-container {
    margin-bottom: 25px;
  }
  .icon-component, .completed-successfully-subtext {
    vertical-align: middle;
  }
  .check-status-button {
    border: 1px solid black;
  }
`;

const RegisterIndividualSuccess = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        groupKey: lodashGet(state, 'registerGroupStagesData.key')
    }),
    {})(RegisterIndividualSuccessComponent);

export default RegisterIndividualSuccess;
